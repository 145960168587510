import ParticlesBg from "particles-bg";

function ParticlesBgs() {
  let config = {
    num: [1, 50],
    rps: 1.5,
    radius: [5, 100],
    life: [1.5, 10],
    v: [1, 5],
    tha: [-40, 40],
    alpha: [1, 0],
    scale: [0.1, 0.3],
    position: "all",
    color: ["random", "#fc7e41", "#f9c4d8", "#feca39", "#b1dfdd"],
    cross: "dead",
    random: 50,
  };

  return <ParticlesBg type="custom" config={config} bg={true} />;
}

export default ParticlesBgs;
