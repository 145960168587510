import { useState } from "react";
import {
  StepOne,
  StepTwo,
  StepTree,
  StepFour,
  StepFive,
  Confirm,
} from "./Steps";
import ProfileWrapper from "./ProfileWrapper";

// import "../../assets/css/antd.css";
// import "./newProfile.scss";

const NewProfile = ({ handleProfilePopup, profileState }) => {
  const [formStep, setFormStep] = useState(0);

  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);

  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);

  return (
    <ProfileWrapper
      setFormStep={setFormStep}
      currentStep={formStep}
      prevFormStep={prevFormStep}
      handleProfilePopup={handleProfilePopup}
      profileState={profileState}
    >
      <StepOne formStep={formStep} nextFormStep={nextFormStep} />
      <StepTwo
        formStep={formStep}
        nextFormStep={nextFormStep}
        prevFormStep={prevFormStep}
      />
      <StepTree
        formStep={formStep}
        nextFormStep={nextFormStep}
        prevFormStep={prevFormStep}
      />
      <StepFour
        formStep={formStep}
        nextFormStep={nextFormStep}
        prevFormStep={prevFormStep}
      />
      <StepFive
        formStep={formStep}
        nextFormStep={nextFormStep}
        prevFormStep={prevFormStep}
      />
      {formStep > 4 && (
        <Confirm
          handleProfilePopup={handleProfilePopup}
          setFormStep={setFormStep}
          formStep={formStep}
        />
      )}
      {/* {formStep === 0 && (
        <StepOne formStep={formStep} nextFormStep={nextFormStep} />
      )}
      {formStep === 1 && (
        <StepTwo
          formStep={formStep}
          nextFormStep={nextFormStep}
          prevFormStep={prevFormStep}
        />
      )}
      {formStep === 2 && (
        <StepTree
          formStep={formStep}
          nextFormStep={nextFormStep}
          prevFormStep={prevFormStep}
        />
      )}
      {formStep === 3 && (
        <StepFour
          formStep={formStep}
          nextFormStep={nextFormStep}
          prevFormStep={prevFormStep}
        />
      )}
      {formStep === 4 && (
        <StepFive
          formStep={formStep}
          nextFormStep={nextFormStep}
          prevFormStep={prevFormStep}
        />
      )}

      {formStep > 4 && (
        <Confirm
          handleProfilePopup={handleProfilePopup}
          setFormStep={setFormStep}
        />
      )} */}
    </ProfileWrapper>
  );
};

export default NewProfile;
