import { useForm, Controller } from "react-hook-form";
import { AddressSuggestions } from "react-dadata";
import { useFormData } from "../../../context";
import { DatePicker, Space, ConfigProvider, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";

const StepTwo = ({ nextFormStep, prevFormStep, formStep }) => {
  const { setFormValues } = useFormData();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };

  return (
    <div className={formStep === 1 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Дата:</label>
        <Controller
          control={control}
          name="date"
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <Space direction="vertical">
                <ConfigProvider>
                  <DatePicker
                    {...register("date", {
                      required: true,
                      validate: (value) => value !== "",
                      onChange: (value) =>
                        setValue(
                          "event_date",
                          moment(value.target.value._d).format("DD.MM.YYYY")
                        ),
                    })}
                    {...field}
                    format="DD.MM.YYYY"
                    style={{ width: "100%" }}
                    locale={locale}
                  />
                </ConfigProvider>
              </Space>
            </>
          )}
        />

        {errors.date && <p>Укажите дату мероприятия</p>}
        <label>Время начала мероприятия:</label>
        <Controller
          control={control}
          name="time"
          render={({ field: { value, onChange } }) => (
            <>
              <TimePicker
                {...register("time", {
                  required: true,
                  validate: (value) => value !== "",
                  onChange: (value) =>
                    setValue(
                      "event_time",
                      moment(value.target.value._d).format("HH:mm")
                    ),
                })}
                value={value}
                onChange={onChange}
                locale={locale}
                format="HH:mm"
              ></TimePicker>
            </>
          )}
        />

        {errors.time && <p>Укажите время начала мероприятия</p>}
        <label>Адрес:</label>
        <Controller
          control={control}
          name="adress"
          render={({ field }) => (
            <>
              <AddressSuggestions
                {...register("adress", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                count={5}
                inputProps={{ placeholder: "г. Москва ул. Новый Арбат 12" }}
                token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
              ></AddressSuggestions>
            </>
          )}
        />

        {errors.adress && <p>Где будет проходить мероприятие?</p>}

        <div className="btn-block">
          <button
            onClick={prevFormStep}
            className="btn order_btn order_btn-back"
          >
            Назад
          </button>
          <button type="submit" className="btn order_btn">
            Далее
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
