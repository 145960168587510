import { Component } from "react";
import Slider from "react-slick";

import example1 from "../../assets/img/example_1.png";
import example2 from "../../assets/img/example_2.png";
import example3 from "../../assets/img/example_3.png";

export default class CenterMode extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: document.body.clientWidth < 500 ? 1 : 3,
      speed: 400,
      autoplay: true,
      autoplaySpeed: 2000,
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src={example1} alt="example" />
          </div>
          <div>
            <img src={example2} alt="example" />
          </div>
          <div>
            <img src={example3} alt="example" />
          </div>
          <div>
            <img src={example1} alt="example" />
          </div>
          <div>
            <img src={example2} alt="example" />
          </div>
          <div>
            <img src={example3} alt="example" />
          </div>
        </Slider>
      </div>
    );
  }
}
