import { useForm, Controller } from "react-hook-form";
import { Select, Input } from "antd";
import { useFormData } from "../../../context";

const StepOne = ({ formStep, nextFormStep }) => {
  const { setFormValues } = useFormData();
  const { Option } = Select;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };

  return (
    <div className={formStep === 0 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Вид мероприятия:</label>
        <Controller
          control={control}
          name="event"
          render={({ field }) => (
            <>
              <Select
                {...register("event", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="День рождения"
                style={{
                  width: "100%",
                }}
              >
                <Option value="День рождения">День рождения</Option>
                <Option value="Вечеринка">Вечеринка</Option>
                <Option value="Выпускной">Выпускной</Option>
                <Option value="Корпоратив">Корпоратив</Option>
                <Option value="Мальчишник">Мальчишник</Option>
                <Option value="Девичник">Девичник</Option>
                <Option value="Свадьба">Свадьба</Option>
              </Select>
            </>
          )}
        />
        {errors.event && <p>Выберите мероприятие</p>}

        <label>Продолжительность:</label>
        <Controller
          control={control}
          name="interval"
          render={({ field }) => (
            <>
              <Input
                {...register("interval", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="1 час"
                style={{
                  width: "100%",
                }}
              ></Input>
            </>
          )}
        />

        {errors.interval && <p>Укажите продолжительность</p>}

        <label>Для кого праздник:</label>
        <Controller
          control={control}
          name="people_category"
          render={({ field }) => (
            <>
              <Select
                {...register("people_category", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Дети"
                style={{
                  width: "100%",
                }}
              >
                <Option value="Дети">Дети</Option>
                <Option value="Подростки">Подростки</Option>
                <Option value="Взрослые">Взрослые</Option>
              </Select>
            </>
          )}
        />
        {errors.people_category && <p>Выберите возрастную категорию</p>}
        <label>Колличество гостей:</label>
        <Controller
          control={control}
          name="people_count"
          render={({ field }) => (
            <>
              <Select
                {...register("people_count", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="До 10"
                style={{
                  width: "100%",
                }}
              >
                <Option value="До 10">До 10</Option>
                <Option value="11-20">11-20</Option>
                <Option value="21-50">21-50</Option>
                <Option value="51-100">51-100</Option>
                <Option value="101-200">101-200</Option>
                <Option value="<Более 200">Более 200</Option>
              </Select>
            </>
          )}
        />
        {errors.people_count && (
          <p>Выберите колличество людей на мероприятии</p>
        )}
        <div style={{ justifyContent: "center" }} className="btn-block">
          <button type="submit" className="btn order_btn">
            Далее
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepOne;
