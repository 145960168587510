import { useForm, Controller } from "react-hook-form";
import { Select, Input } from "antd";
import { useFormData } from "../../../context";

function StepFour({ prevFormStep, nextFormStep, formStep }) {
  const { setFormValues } = useFormData({ mode: "all" });
  const { Option } = Select;
  const {
    register,
    handleSubmit,

    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };
  return (
    <div className={formStep === 3 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Вид проводимых мероприятий:</label>
        <Controller
          control={control}
          name="event"
          render={({ field }) => (
            <>
              <Select
                {...register("event", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="День рождения"
                mode="multiple"
                showArrow
                style={{
                  width: "100%",
                }}
              >
                <Option value="День рождения">День рождения</Option>
                <Option value="Вечеринка">Вечеринка</Option>
                <Option value="Выпускной">Выпускной</Option>
                <Option value="Корпоратив">Корпоратив</Option>
                <Option value="Мальчишник">Мальчишник</Option>
                <Option value="Девичник">Девичник</Option>
                <Option value="Свадьба">Свадьба</Option>
              </Select>
            </>
          )}
        />
        {errors.event && <p>Выберите мероприятия, которые можете проводить</p>}

        <label>Для кого проводите мероприятия:</label>
        <Controller
          control={control}
          name="people_category"
          render={({ field }) => (
            <>
              <Select
                {...register("people_category", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Дети"
                mode="multiple"
                showArrow
                style={{
                  width: "100%",
                }}
              >
                <Option value="Дети">Дети</Option>
                <Option value="Подростки">Подростки</Option>
                <Option value="Взрослые">Взрослые</Option>
              </Select>
            </>
          )}
        />
        {errors.people_category && <p>Выберите возрастную категорию</p>}
        <label>Где проводите мероприятия:</label>
        <Controller
          control={control}
          name="adress"
          render={({ field }) => (
            <>
              <Select
                {...register("adress", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Москва"
                showArrow
                style={{
                  width: "100%",
                }}
              >
                <Option value="Москва">Москва</Option>
                <Option value="Москва и МО">Москва и МО</Option>
              </Select>
            </>
          )}
        />
        {errors.adress && <p>Выберите колличество людей на мероприятии</p>}
        <div className="btn-block">
          <button
            onClick={prevFormStep}
            className="btn order_btn order_btn-back"
          >
            Назад
          </button>
          <button className="btn order_btn" type="submit">
            Далее
          </button>
        </div>
      </form>
    </div>
  );
}

export default StepFour;
