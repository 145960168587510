import classNames from "classnames";

function ProfileWrapper({
  children,
  currentStep,
  handleProfilePopup,
  profileState,
  setFormStep,
}) {
  return (
    <div
      className={classNames("form_card", { "form_card-open": profileState })}
    >
      <div className={"order_form"}>
        <span
          onClick={() => {
            handleProfilePopup();
          }}
          className="close"
        >
          &times;
        </span>
        {currentStep <= 4 && (
          <>
            <div className="order_form-title">
              <h2>Заполните анкету</h2>
              <p>Шаг {currentStep + 1} из 5</p>
              <p>Информация об оказываемых услугах</p>
            </div>
            <div className="order_form-progress">
              <div
                style={{
                  width: `${
                    currentStep == 0
                      ? "20%"
                      : currentStep == 1
                      ? "40%"
                      : currentStep == 2
                      ? "60%"
                      : currentStep == 3
                      ? "80%"
                      : "100%"
                  }`,
                }}
                className="order_form-progress-line"
              ></div>
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
}

export default ProfileWrapper;
