import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useFormData } from "../../../context";

// import "./confirm.scss";

function Confirm({ setFormStep, handlePopup, formStep }) {
  const { data, setFormValues } = useFormData();

  let [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      console.log(data);
      await fetch("../phpmailer/profile.php", {
        body: JSON.stringify(data),
        method: "POST",
      }).then(() => {
        setIsLoading(false);
        setFormValues({});
      });
    }
    fetchData();
  }, []);

  return (
    <div className="confirm">
      {isLoading ? (
        <LoadingOutlined style={{ fontSize: "50px" }} />
      ) : (
        <>
          <h2>Ваша анкета отправлена!</h2>
          <p>
            Ваша анкета оправлена! Начинаем верификацию! Мы с Вами свяжемся, как
            только закончим!
          </p>
        </>
      )}
    </div>
  );
}

export default Confirm;
