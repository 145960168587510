import { useForm, Controller } from "react-hook-form";
import { Input } from "antd";
import { useFormData } from "../../../context";

function StepOne({ formStep, nextFormStep }) {
  const { setFormValues } = useFormData({ mode: "all" });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };
  return (
    <div className={formStep === 0 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Фамилия:</label>
        <Controller
          control={control}
          name="lastname"
          render={({ field }) => (
            <>
              <Input
                {...register("lastname", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Иванов"
                style={{
                  width: "100%",
                }}
              ></Input>
            </>
          )}
        />
        {errors.lastname && <p>Введите Вашу фамилию</p>}

        <label>Имя:</label>
        <Controller
          control={control}
          name="firstname"
          render={({ field }) => (
            <>
              <Input
                {...register("firstname", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Петр"
                style={{
                  width: "100%",
                }}
              ></Input>
            </>
          )}
        />

        {errors.firstname && <p>Введите Ваше имя</p>}

        <label>Отчество:</label>
        <Controller
          control={control}
          name="middlename"
          render={({ field }) => (
            <>
              <Input
                {...register("middlename", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Иванович"
                style={{
                  width: "100%",
                }}
              ></Input>
            </>
          )}
        />

        {errors.middlename && <p>Введите Ваше отчество</p>}

        <div style={{ justifyContent: "center" }} className="btn-block">
          <button className="btn order_btn">Далее</button>
        </div>
      </form>
    </div>
  );
}

export default StepOne;
