import {
  FeedbackForm,
  ParticlesBgs,
  Slick,
  OrderForm,
  ProfileForm,
} from "./components";
import FormProvider from "./context";
import CountUp from "react-countup";
import logo from "./assets/img/Vector.svg";
import arrow from "./assets/img/arrow.png";
import price from "./assets/img/price/price.png";
import price1 from "./assets/img/price/price1.png";
import price2 from "./assets/img/price/price2.png";
import price3 from "./assets/img/price/price3.png";
import price4 from "./assets/img/photosession.jpeg";
import payment from "./assets/img/payments.png";
import pay_method from "./assets/img/pay_method.png";
import card from "./assets/img/card.svg";
import qr from "./assets/img/qr.svg";
import cash from "./assets/img/cash.svg";

import girl from "./assets/img/girl.png";
import man from "./assets/img/man.png";
import "./App.scss";

import { Feetback } from "./layouts";
import { useState } from "react";

import "antd/dist/antd.css";

function App() {
  let [orderState, setOrderState] = useState(false);
  let [profileState, setProfileState] = useState(false);

  const handleOrderPopup = () => {
    setOrderState(!orderState);
  };
  const handleProfilePopup = () => {
    setProfileState(!profileState);
  };

  return (
    <>
      <header>
        <div className="header_content">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
          <nav>
            <ul>
              <li>
                <a href="#example">Примеры работ</a>
              </li>
              <li>
                <a href="#feedback">Отзывы</a>
              </li>
              <li>
                <a href="#steps">Как это работает</a>
              </li>
              <li>
                <a href="#price">Цены</a>
              </li>
              <li>
                <a href="#form">Обратная связь</a>
              </li>
            </ul>
          </nav>
          <button onClick={handleProfilePopup} className="btn">
            Стать ведущим
          </button>
          <button onClick={handleOrderPopup} className="btn">
            Найти специалиста
          </button>
        </div>
      </header>
      <div className="App">
        <div className="section">
          <div className="event">
            <div className="event_info">
              <h1>Организация фотосессий и мероприятий</h1>
              <p>
                Поможем сделать ваш день веселым и запоминающимся. <br />{" "}
                Ведущие, зарегистрированные на нашем сайте, организуют <br />{" "}
                любой праздник на высшем уровне.
              </p>
              <div className="event_info-btn">
                <button
                  onClick={handleOrderPopup}
                  className="btn btn-primary btn-active"
                >
                  Найти специалиста
                </button>
                <button
                  onClick={handleProfilePopup}
                  className="btn btn-primary"
                >
                  Стать ведущим
                </button>
              </div>
            </div>
            <div className="event_img">
              <img src={girl} alt="girl" />
            </div>
          </div>
        </div>
        <div className="section">
          <div className="stat">
            <div className="stat_block">
              <h3>
                <CountUp end={467} useEasing={true} />
              </h3>
              <p>Ведущих</p>
            </div>
            <div className="stat_block">
              <h3>
                <CountUp end={99} useEasing={true} />%
              </h3>
              <p>
                Положительных <br /> отзывов
              </p>
            </div>
            <div className="stat_block">
              <h3>
                <CountUp end={1529} useEasing={true} />
              </h3>
              <p>Клиентов</p>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="event event-two">
            <div className="event_img event_img-two">
              <img src={man} alt="man" />
            </div>
            <div className="event_info event_info-two">
              <h2>
                Каждый праздник пройдет <br /> по-новому
              </h2>
              <p>
                Исполнители обсуждают и согласовывают нюансы программы
                праздника, чтобы ожидания клиента соответствовали реальности.
                Можете ни о чем не беспокоиться!
              </p>
              <div className="event_info-btn">
                <button
                  onClick={handleOrderPopup}
                  className="btn btn-primary btn-active"
                >
                  Найти специалиста
                </button>
                <button
                  onClick={handleProfilePopup}
                  className="btn btn-primary"
                >
                  Стать ведущим
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="example" className="section">
          <div className="example">
            <h2>Примеры работ</h2>
            <Slick />
          </div>
        </div>
        <div id="feedback" className="section">
          <div className="feetback">
            <div className="feetback_title">
              <h2>Отзывы</h2>
            </div>
            <div className="feetback_card">
              <Feetback />
            </div>
          </div>
        </div>
        <div id="steps" className="section">
          <div className="steps">
            <div className="steps_title">
              <h2>Как это работает?</h2>
            </div>
            <div className="steps_card">
              <div className="steps_card-item">
                <div className="steps_card-item-title">
                  <h1>01.</h1>
                  <img src={arrow} alt="arrow" />
                  <h3>Заполните заявку</h3>
                </div>
                <div className="steps_card-item-text">
                  <p>
                    Подберем оптимальные варианты ведущих на ваше мероприятие.
                  </p>
                </div>
              </div>
              <div className="steps_card-item">
                <div className="steps_card-item-title">
                  <h1>02.</h1>
                  <img src={arrow} alt="arrow" />
                  <h3>Выберите подходящего ведущего</h3>
                </div>
                <div className="steps_card-item-text">
                  <p>
                    Вам предложат концепцию проведения мероприятия - остается
                    только выбрать подходящий вариант для вас.
                  </p>
                </div>
              </div>
              <div className="steps_card-item">
                <div className="steps_card-item-title">
                  <h1>03.</h1>
                  <img src={arrow} alt="arrow" />
                  <h3>Согласуйте с ведущим все детали</h3>
                </div>
                <div className="steps_card-item-text">
                  <p>
                    Обсудите все детали мероприятия и наслаждайтесь ожиданием
                    вашего события.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="price" className="section">
          <div className="price">
            <div className="price_title">
              <h2>Цены</h2>
            </div>
            <div className="price_card">
              <div className="price_card-item">
                <div className="price_card-item-img">
                  <img src={price1} alt="" />
                </div>
                <div className="price_card-item-text">
                  <p>Ведущие на детский праздник</p>
                  <span>от 2500 ₽</span>
                </div>
              </div>
              <div className="price_card-item">
                <div className="price_card-item-img">
                  <img
                    style={{
                      height: "150px",
                      borderRadius: "10px",
                    }}
                    src={price4}
                    alt=""
                  />
                </div>
                <div className="price_card-item-text">
                  <p>Фотосессия на любое мероприятие</p>
                  <span>от 3000 ₽</span>
                </div>
              </div>
              <div className="price_card-item">
                <div className="price_card-item-img">
                  <img src={price1} alt="" />
                </div>
                <div className="price_card-item-text">
                  <p>Ведущие для подростков</p>
                  <span>от 3400 ₽ </span>
                </div>
              </div>
              <div className="price_card-item">
                <div className="price_card-item-img">
                  <img src={price3} alt="" />
                </div>
                <div className="price_card-item-text">
                  <p>
                    Ведущие <br /> для взрослых
                  </p>
                  <span>от 5500 ₽ </span>
                </div>
              </div>
              <div className="price_card-item">
                <div className="price_card-item-img">
                  <img src={price} alt="price" />
                </div>
                <div className="price_card-item-text">
                  <p>Ведущие на мероприятия</p>
                  <span>от 7500 ₽ </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="payments">
            <div className="payments_block">
              <div className="payments_block-info">
                <h2>Способы оплаты</h2>

                <p>
                  Согласуйте все детали мероприятия с ведущим, выберите <br />{" "}
                  удобный для себя способ оплаты и оплатите услугу.
                </p>

                <div className="payments_block-info-method">
                  <img src={card} alt="Карта" />
                  <span>
                    {" "}
                    <b>Карта</b>{" "}
                  </span>
                </div>
                <div className="payments_block-info-method">
                  <img src={qr} alt="QR" />
                  <span className="unavailable">
                    <b>QR-код</b>(временно недоступно)
                  </span>
                </div>
                <div className="payments_block-info-method">
                  <img src={cash} alt="Наличные" />
                  <span className="unavailable">
                    <b>Наличные</b>(временно недоступно)
                  </span>
                </div>
              </div>
              <div className="payments_block-img">
                <img src={pay_method} alt="Способы оплаты" />
              </div>
            </div>
          </div>
        </div>
        <div id="form" className="section">
          <div className="feedback">
            <div className="feedback_title">
              <h2>Свяжитесь с нами</h2>
            </div>
            <div className="feedback_form">
              <FeedbackForm />
            </div>
          </div>
        </div>
        <FormProvider>
          <OrderForm
            handleOrderPopup={handleOrderPopup}
            orderState={orderState}
          />
        </FormProvider>

        <FormProvider>
          <ProfileForm
            handleProfilePopup={handleProfilePopup}
            profileState={profileState}
          />
        </FormProvider>
        {/* <FormProvider>
          <NewProfile
            handleProfilePopup={handleProfilePopup}
            profileState={profileState}
          />
        </FormProvider> */}
      </div>
      <footer>
        <div className="footer_content">
          <div className="footer_content-logo">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="footer_content-contacts">
            <h4>Контакты</h4>
            <p>г. Москва, ул. Авиамоторная, д.55, к.31, этаж 3, офис 3610</p>
            <p>info-digitalpartner@yandex.com</p>
          </div>
          <div className="footer_content-req">
            <h4>Реквизиты</h4>
            <p>ООО "ДИДЖИТАЛ ПАРТНЕР"</p>
            <p>ИНН: 7722494880</p>
            <p>КПП: 772201001</p>
          </div>
          {/* <div className="footer_content-nav">
            <nav>
              <ul>
                <li>
                  <a href="#example">Примеры работ</a>
                </li>
                <li>
                  <a href="#feedback">Отзывы</a>
                </li>
                <li>
                  <a href="#steps">Как это работает</a>
                </li>
                <li>
                  <a href="#price">Цены</a>
                </li>
                <li>
                  <a href="#form">Обратная связь</a>
                </li>
              </ul>
            </nav>
          </div> */}
          <div className="docs">
            <h4>Документы</h4>
            <a href="./eventdocs/terms.pdf">Условия оказания услуг</a>
            <a href="./eventdocs/agreement.pdf">Пользовательское соглашение</a>
            <a href="./eventdocs/privacy.pdf">
              Политика обработки персональных данных
            </a>
          </div>
          <div className="footer_content-payment">
            <img src={payment} alt="Способы оплаты" />
            <p>© 2022 Event Master</p>
          </div>
        </div>
      </footer>
      <ParticlesBgs />
    </>
  );
}

export default App;
