import { useForm, Controller } from "react-hook-form";
import { useFormData } from "../../../context";
import { Input } from "antd";

function StepFive({ prevFormStep, nextFormStep, formStep }) {
  const { setFormValues } = useFormData({ mode: "all" });
  const { TextArea } = Input;
  const {
    register,
    handleSubmit,

    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };

  return (
    <div className={formStep === 4 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>О себе:</label>
        <Controller
          control={control}
          name="about"
          render={({ field }) => (
            <>
              <TextArea
                {...register("about", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Расскажите о себе, о своем опыте, о преимуществах работы с вами"
                style={{
                  width: "100%",
                  height: "250px",
                }}
              ></TextArea>
            </>
          )}
        />

        {errors.about && <p>Расскажите немного о себе</p>}
        <span className="politics">
          Нажимая кнопку отправить, вы соглашаетесь с{" "}
          <a href="#">политикой обработки персональных данных</a>.
        </span>
        <div className="btn-block">
          <button
            onClick={prevFormStep}
            className="btn order_btn order_btn-back"
          >
            Назад
          </button>
          <button className="btn order_btn" type="submit">
            Отправить
          </button>
        </div>
      </form>
    </div>
  );
}

export default StepFive;
