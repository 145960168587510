import { useForm, Controller } from "react-hook-form";
import { useFormData } from "../../../context";
import { Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const StepTree = ({ prevFormStep, nextFormStep, formStep }) => {
  const { setFormValues } = useFormData();
  const { TextArea } = Input;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };

  return (
    <div className={formStep === 2 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Имя:</label>
        <Controller
          control={control}
          name="client_name"
          render={({ field }) => (
            <>
              <Input
                {...register("client_name", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Иван"
                style={{
                  width: "100%",
                }}
              ></Input>
            </>
          )}
        />
        {errors.client_name && <p>Введите имя</p>}

        <label>Номер телефона:</label>
        <Controller
          control={control}
          name="client_phone"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <>
              <PhoneInput
                placeholder="+7 (999) 999-99-99"
                onChange={onChange}
                value={value}
                country={"ru"}
                disableDropdown={true}
              />
            </>
          )}
        />
        {errors["client_phone"] && <p>Введите ваш номер телефона</p>}
        <label>Пожелания:</label>
        <Controller
          control={control}
          name="comment"
          render={({ field }) => (
            <>
              <TextArea
                {...register("comment", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                placeholder="Опишите пожелания к мероприятию"
                style={{
                  width: "100%",
                  height: "130px",
                }}
              ></TextArea>
            </>
          )}
        />
        {errors.comment && <p>Опишите ваши пожелания</p>}
        <div className="btn-block">
          <button
            onClick={prevFormStep}
            className="btn order_btn order_btn-back"
          >
            Назад
          </button>
          <button className="btn order_btn" type="submit">
            Отправить
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepTree;
