import { useForm, Controller } from "react-hook-form";
import { useFormData } from "../../../context";
import { Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function StepTree({ prevFormStep, nextFormStep, formStep }) {
  const { setFormValues } = useFormData({ mode: "all" });
  const {
    register,
    handleSubmit,

    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };

  return (
    <div className={formStep === 2 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Почта:</label>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <>
              <Input
                {...register("email", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                type="email"
                placeholder="ivaninf@yandex.com"
                style={{
                  width: "100%",
                }}
              ></Input>
            </>
          )}
        />
        {errors.email && <p>Введите Вашу почту</p>}

        <label>Номер телефона:</label>
        <Controller
          control={control}
          name="phone"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <>
              <PhoneInput
                placeholder="+7 (999) 999-99-99"
                onChange={onChange}
                value={value}
                country={"ru"}
                disableDropdown={true}
              />
            </>
          )}
        />
        {errors["phone"] && <p>Введите ваш номер телефона</p>}

        <div className="btn-block">
          <button
            onClick={prevFormStep}
            className="btn order_btn order_btn-back"
          >
            Назад
          </button>
          <button className="btn order_btn" type="submit">
            Далее
          </button>
        </div>
      </form>
    </div>
  );
}

export default StepTree;
