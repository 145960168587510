import { StepOne, StepTwo, StepTree, Confirm } from "./Steps";
import { OrderWrapper } from "./OrderWrapper";
import { useState } from "react";

const Order = ({ handleOrderPopup, orderState }) => {
  const [formStep, setFormStep] = useState(0);

  const nextFormStep = () => setFormStep((currentStep) => currentStep + 1);

  const prevFormStep = () => setFormStep((currentStep) => currentStep - 1);

  return (
    <OrderWrapper
      setFormStep={setFormStep}
      currentStep={formStep}
      handleOrderPopup={handleOrderPopup}
      orderState={orderState}
    >
      <StepOne formStep={formStep} nextFormStep={nextFormStep} />
      <StepTwo
        formStep={formStep}
        nextFormStep={nextFormStep}
        prevFormStep={prevFormStep}
      />
      <StepTree
        formStep={formStep}
        nextFormStep={nextFormStep}
        prevFormStep={prevFormStep}
      />
      {formStep > 2 && (
        <Confirm
          handleOrderPopup={handleOrderPopup}
          setFormStep={setFormStep}
        />
      )}
    </OrderWrapper>
  );
};

export default Order;
