import classNames from "classnames";

export const OrderWrapper = ({
  children,
  currentStep,
  handleOrderPopup,
  orderState,
  setFormStep,
}) => {
  return (
    <div className={classNames("form_card", { "form_card-open": orderState })}>
      <div className={"order_form"}>
        <span
          onClick={() => {
            handleOrderPopup();
          }}
          className="close"
        >
          &times;
        </span>
        {currentStep <= 2 && (
          <>
            <div className="order_form-title">
              <h2>Оставьте заявку</h2>
              <p>Шаг {currentStep + 1} из 3</p>
              <p>Общая информация о мероприятии</p>
            </div>
            <div className="order_form-progress">
              <div
                style={{
                  width: `${
                    currentStep == 0
                      ? "33.3%"
                      : currentStep == 1
                      ? "66.6%"
                      : "100%"
                  }`,
                }}
                className="order_form-progress-line"
              ></div>
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
};
