import { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./feedbackForm.scss";

function FeedbackForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(data);
    await fetch("../phpmailer/feedback.php", {
      body: JSON.stringify(data),
      method: "POST",
    })
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      })
      .then(() => {
        setTimeout(setIsSuccess, 5000, false);
      });
    reset();
  };

  return (
    <>
      {isLoading ? (
        <LoadingOutlined style={{ fontSize: "50px" }} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {isSuccess && (
            <Alert message="Сообщение отправлено успешно!" type="success" />
          )}
          <label>Имя:</label>
          <input
            placeholder="Иван"
            type="text"
            {...register("name", {
              required: true,
              validate: (value) => value !== "",
            })}
          />
          {errors.name && <p>Введите Ваше имя</p>}
          <label>Почта:</label>
          <input
            placeholder="ivaninf@yandex.com"
            type="email"
            {...register("email", {
              required: true,
              validate: (value) =>
                /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(value),
            })}
          />
          {errors.email && <p>Введите корректный почтовый адрес</p>}
          <label>Заголовок:</label>
          <input
            placeholder="Введите тему письма"
            type="text"
            {...register("title", {
              required: true,
              validate: (value) => value !== "",
            })}
          />
          {errors.title && <p>Введите тему письма</p>}
          <label>Сообщение:</label>
          <textarea
            rows="6"
            placeholder="Введите сообщение"
            type="text"
            {...register("text", {
              required: true,
              validate: (value) => value !== "",
            })}
          />
          {errors.text && <p>Пустое сообщение нельзя отправить</p>}

          <div className="form_btn">
            <button className="btn" type="submit">
              Отправить
            </button>
          </div>
        </form>
      )}
    </>
  );
}

export default FeedbackForm;
