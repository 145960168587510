import "./feetback.scss";
import alexandra from "../../assets/img/avatar/alexandra.png";
import anton from "../../assets/img/avatar/anton.png";
import denis from "../../assets/img/avatar/denis.png";
import irina from "../../assets/img/avatar/irina.png";
import mariya from "../../assets/img/avatar/mariya.png";
import sergey from "../../assets/img/avatar/sergey.png";
import stars from "../../assets/img/avatar/stars.png";

function Feetback() {
  const feetbackData = [
    {
      name: "Ирина В.",
      event: "День рождения",
      avatar: `${irina}`,
      stars: `${stars}`,
      text: "Выражаем огромную благодарность за проведение дня рождения моего сына. Отличная программа - дети в восторге!",
    },
    {
      name: "Сергей И.",
      event: "Праздник в детском саду",
      avatar: `${sergey}`,
      stars: `${stars}`,
      text: "Все остались довольны праздником.  Дети были в надежных руках. Будем  обращаться еще раз.",
    },
    {
      name: "Александра Н.",
      event: "Корпоратив",
      avatar: `${alexandra}`,
      stars: `${stars}`,
      text: "Все супер! Коллеги в восторге, интересная программа, никто не успевал заскучать. Буду рекомендовать вас всем!",
    },
    {
      name: "Антон К.",
      event: "Детская дискотека",
      avatar: `${anton}`,
      stars: `${stars}`,
      text: "Моя дочка до сих пор в восторге от прошедшего праздника. От души веселились не только дети, но и взрослые!",
    },
    {
      name: "Денис М.",
      event: "Корпоратив",
      avatar: `${denis}`,
      stars: `${stars}`,
      text: "Корпоратив прошел на 5+. Команде очень понравилось!",
    },
    {
      name: "Мария С.",
      event: "День рождения",
      avatar: `${mariya}`,
      stars: `${stars}`,
      text: "Заранее продумали всю программу, результат превзошел всн ожидания. Рекомендую однозначно!!!",
    },
  ];

  return (
    <>
      {feetbackData.map(({ name, event, avatar, stars, text }) => {
        return (
          <div key={avatar} className="feetback_card-item">
            <div className="feetback_card-item-person">
              <div className="feetback_card-item-person-avatar">
                <img src={avatar} alt={name} />
              </div>
              <div className="feetback_card-item-person-name">
                <h3>{name}</h3>
                <p>{event}</p>
              </div>
            </div>
            <div className="feetback_card-item-stars">
              <img src={stars} alt="stars" />
            </div>
            <div className="feetback_card-item-text">
              <p>{text}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Feetback;
