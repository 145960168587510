import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { useFormData } from "../../../context";
import { AddressSuggestions } from "react-dadata";
import { DatePicker, Space, ConfigProvider } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import moment from "moment";

function StepTwo({ nextFormStep, prevFormStep, formStep }) {
  const { setFormValues } = useFormData({ mode: "all" });
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setFormValues(value);
    nextFormStep();
  };
  return (
    <div className={formStep === 1 ? "showForm" : "hideForm"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>Серия и номер паспорта:</label>
        <Controller
          control={control}
          name="pasport_serial"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <>
              <InputMask
                {...register("pasport_serial", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                name="pasport_serial"
                mask="99 99 999999"
                maskChar={null}
                placeholder="00 00 000000"
                style={{
                  width: "100%",
                }}
              >
                {(inputProps) => <input {...inputProps} type="text" />}
              </InputMask>
            </>
          )}
        />
        {errors.pasport_serial && <p>Введите серию и номер паспорта</p>}

        <label>Дата выдачи:</label>
        <Controller
          control={control}
          name="date"
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <Space direction="vertical">
                <ConfigProvider>
                  <DatePicker
                    {...register("date", {
                      required: true,
                      validate: (value) => value !== "",
                      onChange: (value) =>
                        setValue(
                          "pasport_date",
                          moment(value.target.value._d).format("DD.MM.YYYY")
                        ),
                    })}
                    {...field}
                    format="DD.MM.YYYY"
                    style={{ width: "100%" }}
                    locale={locale}
                  />
                </ConfigProvider>
              </Space>
            </>
          )}
        />

        {errors.date && <p>Укажите дату выдачи паспорта</p>}

        <label>Адрес регистрации:</label>
        <Controller
          control={control}
          name="reg_adress"
          render={({ field }) => (
            <>
              <AddressSuggestions
                {...register("reg_adress", {
                  required: true,
                  validate: (value) => value !== "",
                })}
                {...field}
                count={5}
                inputProps={{ placeholder: "г. Москва ул. Новый Арбат 12" }}
                token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
              ></AddressSuggestions>
            </>
          )}
        />

        {errors.reg_adress && <p>Укажите адрес регистрации</p>}

        <div className="btn-block">
          <button
            onClick={prevFormStep}
            className="btn order_btn order_btn-back"
          >
            Назад
          </button>
          <button className="btn order_btn">Далее</button>
        </div>
      </form>
    </div>
  );
}

export default StepTwo;
